const products = {
    title: 'Produkty',
    heading: 'Nasze produkty',
    protein: 'Proteiny',
    protein_dsc:
        'Rozumiemy znaczenie białka jako budulca dla mięśni. Oferujemy różnorodne produkty proteinowe, które wykorzystują szeroką gamę źródeł białka, takich jak: izolat białka serwatkowego, białko kazeinowe, białko sojowe lub białko jajeczne. Oferujemy opcje zarówno dla mięsożerców, jak i wegan.',
    pre_workout: 'Przedtreningówki',
    pre_workout_dsc:
        'Zadaniem suplementów przedtreningowych jest  zwiększenie poziomu engergii, co zapewni maksymalną wydajność treningu. Przedtreningówki różnią się formą, siłą oraz składnikami. Oferujemy produkty przedtreningowe, które będą odpowiadały Twoim celom fitness, poziomowi doświadczenia, a także smakowi.',
    mass_gainer: 'Gainery',
    mass_gainer_dsc:
        'Budowanie masy mięśniowej to ciężka praca. Jeśli przegrywasz walkę o przyrosty, być może czas pomyśleć o stworzeniu odpowiedniej nadwyżki kalorycznej i białkowej. Gainery pomogą Ci wrócić na właściwy tor uzupełniając deficyt kaloryczny i przezwyciężyć zastój.',
    weight_loss: 'Spalacze Tłuszczu',
    weight_loss_dsc:
        'Osiągnięcie i utrzymanie idealnej wagi ciała jest niezwykle trudne. Odpowiednia suplementacja pomoże Ci zrzucić zbędne kilogramy i spalić niechciany tłuszcz. Suplementy tego typu działają poprzez zwiększenie wydalania wody z organizmu, przyspieszenie metabolizmu lub regulację pracy tarczycy. Posiadamy również produkty bez stymulantów, a także kombinację powyższych typów.',
    creatine: 'Kreatyny',
    creatine_dsc:
        'Kreatyna jest podstawowym suplementem używanym przez kulturystów i sportowców wyczynowych w celu zwiększenia wydajności i stymulacji wzrostu mięśni. Mięśnie szkieletowe wykorzystują kreatynę jako magazyn energii do skurczu. Suplementacja kreatyną prowadzi do lepszego przyrostu mięśni, mniejszej ilości urazów oraz do zwiększenia mocy i wydajności.',
    aminos: 'Aminokwasy',
    aminos_dsc:
        'Aminokwasy to związki organiczne, których unikalną właściwością jest to, że są elementem budulcowym innych związków, takich jak proteiny. Zdrowy organizm potrzebuje dwudziestu różnych aminokwasów, aby działać sprawnie. Nasz organizm jest w stanie wytworzyć osiem z nich, ale resztę musimy zapewnić dietą lub suplementacją. Aminokwasy są niezbędne do budowy, właściwego działania i naprawy mięśni.',
    vitamins: 'Witaminy i Minerały',
    vitamins_dsc:
        'Witaminy i minerały są kluczem do utrzymania zdrowego i silnego organizmu. Ilość i rodzaj witamin i minerałów wymaganych przez organizm uzależniony jest od czynników takich jak wiek, płeć, aktualna kondycja fizyczna oraz typ budowy ciała. Możesz wybierać między związkami multiwitaminowymi i suplementami odpowiadającymi na konkretne potrzeby witaminowe lub mineralne. ',
}

export default products
