const homepage = {
    title: 'Witamy',
    welcome: 'Witamy w Protein Zone',
    opening_times: 'Godziny otwarcia',
    mon: 'Poniedziałek',
    tue: 'Wtorek',
    wed: 'Środa',
    thu: 'Czwartek',
    fri: 'Piątek',
    sat: 'Sobota',
    sun: 'Niedziela',
    visit_store: 'Zapraszamy do naszego sklepu',
    in_centre: 'Jesteśmy w samym sercu Dublina',
    contact_us: 'Kontakt',
    call_us: 'Zadzwoń do nas',
    find_us_on_social: 'Znajdź nas na mediach społecznościowych',
    click_map: 'Kliknij na mapę',
    about_store: 'O naszym sklepie',
    established_info:
        'Rozpoczęliśmy działalność w 2013r. Jesteśmy najszybciej rozwijającym się sprzedawcą odżywek sportowych oraz suplementów diety w Irlandii.',
    we_offer:
        'Oferujemy szeroką gamę Suplementów Kulturystycznych, w tym: szejki proteinowe, odżywki na przyrost masy, aminokwasy, suplementy na poprawę wydolności oraz regeneracje. Wszystko w atrakcyjnych i przystępnych cenach.',
    located_extended:
        'Znajdujemy się w samym centrum Dublina, przy ul. 20 Abbey Street Upper, na przeciwko głównego wejścia do Jervis Shopping Centre, siłowni FLYEfit oraz Przystanku Luasa Jervis.',
    we_stock:
        'Sprowadzamy najlepsze światowe marki, takie jak:  BSN, Biotech USA, Olimp Nutrition, Optimum Nutrition, Mutant, Scitec Nutrition oraz Universal Nutrition. ',
    meet_team: 'Nasza ekipa',
    store_manager: 'Menadżer Sklepu',
    personal_trainer: 'Trener Personalny',
    qualified_dietician: 'Wykwalifikowany Dietetyk',
    bodybuilder: 'Kulturysta',
    nabba_winner: 'Zwycięzca NABBA',
    answer_questions:
        'Jestem tutaj, aby udzielić wskazówek i odpowiedzieć na pytania dotyczące suplementacji, treningu oraz diety.',
    drop_in: 'Wpadnij do nas na rozmowę.',
    partners: 'Partnerzy',
    megapump_offer: 'Kupujący online mogą skorzystać z kodu promocyjnego MEGA5 na stronie naszego partnera—megapump.ie—kod uprawnia do specjalnego 5% rabatu.',

}

export default homepage
