const products = {
    title: 'Products',
    heading: 'Our products',
    protein: 'Protein',
    protein_dsc:
        'We understand the importance of protein as building blocks for muscles. We stock a variety of protein products that utilise a broad range of protein sources such as: whey isolate protein, casein protein, soy protein or egg protein. We provide options for carnivores and vegans alike.',
    pre_workout: 'Pre-Workouts',
    pre_workout_dsc:
        'Pre-workout supplements give you a boost of energy to ensure that you go through your workout at peak performance. Pre-workouts vary in form, strength, and ingredients. We stock pre-workout products that will suit your fitness goals, experience level and taste.',
    mass_gainer: 'Mass Gainers',
    mass_gainer_dsc:
        'Building muscle mass is hard work. If you find yourself struggling for gains, you might have to think about creating a sufficient calorie and protein surplus. Mass gainers can get you back on track by filling in the calorie deficit and push you passed that plateau.',
    weight_loss: 'Weight Loss',
    weight_loss_dsc:
        'Reaching and maintaining the perfect bodyweight is extremely challenging. The right supplementation will help you lose excess weight and burn unwanted fat. These supplements work by increasing fluid removal, increasing metabolism or regulating the thyroid. We also stock stimulant-free supplements as well as a combination of the above types.',
    creatine: 'Creatine',
    creatine_dsc:
        'Creatine is a staple supplement used by body-builders and high performance athletes to boost performance and stimulate muscle growth. Skeletal muscles use creatine as energy store for contracting. Creatine supplementation leads to better muscle growth, lower muscle deterioration, and increased power and performance.',
    aminos: 'Amino Acids',
    aminos_dsc:
        'Amino acids are organic compounds that have a unique property of being the building blocks or other compounds such as protein. A healthy body requires twenty different amino acids to run smoothly. Our bodies can make eight of them but we have to provide the rest by diet or supplementation. Amino acids are essential for muscle building, maintenance and repair.',
    vitamins: 'Vitamins & Minerals',
    vitamins_dsc:
        'Vitamins and minerals are key to keeping the body healthy and strong. The levels and types of vitamins & minerals required by the body also varies by each individual based on factors such as age, sex, current physical shape and body type. You can chose between multivitamin compounds and supplements answering specific vitamin or mineral needs.',
}

export default products
