import homepage from './homepage'
import about from './about'
import staff from './staff'
import projects from './projects'
import contact from './contact'
import error404 from './error404'
import products from './products'

const pl = {
    homepage,
    about,
    staff,
    projects,
    contact,
    error404,
    products,
}

export default pl
