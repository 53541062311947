const products = {
    title: 'Produtos',
    heading: 'Nossos produtos',
    protein: 'Proteínas',
    protein_dsc:
        'Compreendemos a importância das proteínas como blocos de construção para os músculos. Temos em estoque uma variedade de produtos de proteína que utilizam uma ampla gama de fontes de proteína, como: proteína isolada de soro de leite, caseína, proteína de soja ou proteína de ovo. Oferecemos opções para carnívoros e veganos.',
    pre_workout: 'Pré-treinos',
    pre_workout_dsc:
        'Os suplementos pré-treino dão-lhe um impulso de energia para garantir que você realize o treino com o máximo desempenho. Os pré-treinos variam em forma, força e ingredientes. Temos em estoque produtos pré-treino que vão de encontro aos seus objetivos de condicionamento físico, nível de experiência e gosto.',
    mass_gainer: 'Ganhadores de massa',
    mass_gainer_dsc:
        'Construir massa muscular é um trabalho árduo. Se você está lutando para obter ganhos, pode ter que pensar em criar um excedente de calorias e proteínas suficiente. Os ganhadores de massa podem colocá-lo de volta nos trilhos, preenchendo o déficit calórico e fazendo com que você ultrapasse essa barreira.',
    weight_loss: 'Perda de gordura',
    weight_loss_dsc:
        'Alcançar e manter o peso corporal perfeito é extremamente desafiador. A suplementação certa o ajudará a perder o excesso de peso e queimar as gorduras indesejadas. Esses suplementos atuam aumentando a remoção de fluidos, aumentando o metabolismo ou regulando a tireóide. Também temos em estoque suplementos sem estimulantes, bem como uma combinação dos tipos acima.',
    creatine: 'Creatina',
    creatine_dsc:
        'A creatina é um suplemento básico usado por fisiculturistas e atletas de alto desempenho para aumentar o desempenho e estimular o crescimento muscular. Os músculos esqueléticos usam a creatina como armazenamento de energia para a contração. A suplementação de creatina leva a um melhor crescimento muscular, diminui a deterioração muscular e aumenta a potência e o desempenho.',
    aminos: 'Aminoácidos',
    aminos_dsc:
        'Os aminoácidos são compostos orgânicos que têm a propriedade única de serem elementos de construção ou outros compostos, como proteínas. Um corpo saudável requer vinte aminoácidos diferentes para funcionar sem problemas. Nossos corpos podem fazer oito deles, mas temos que fornecer o resto por meio de dieta ou suplementação. Os aminoácidos são essenciais para a construção, manutenção e reparação muscular.',
    vitamins: 'Vitaminas e Minerais',
    vitamins_dsc:
        'As vitaminas e os minerais são essenciais para manter o corpo saudável e forte. Os níveis e tipos de vitaminas e minerais exigidos pelo corpo também variam de indivíduo para indivíduo com base em fatores como idade, sexo, condição física atual e tipo de corpo. Você pode escolher entre compostos multivitamínicos e suplementos que atendam a necessidades específicas de vitaminas ou minerais.',
}

export default products
