module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200,"backgroundColor":"transparent"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Protein Zone","short_name":"PtnZone","start_url":"/","background_color":"#0E283F","theme_color":"#0E283F","display":"fullscreen","icon":"src/assets/images/branding/pz-icon-logo.png","icon_options":{"purpose":"maskable"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"htmlTitle":"Protein Zone CMS"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
