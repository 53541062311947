const homepage = {
    title: 'Welcome',
    welcome: 'Welcome to Protein Zone',
    opening_times: 'Opening times',
    mon: 'Monday',
    tue: 'Tuesday',
    wed: 'Wednesday',
    thu: 'Thursday',
    fri: 'Friday',
    sat: 'Saturday',
    sun: 'Sunday',
    visit_store: 'Visit our store',
    in_centre: "We're right in the heart of Dublin",
    contact_us: 'Contact us',
    call_us: 'Call us',
    find_us_on_social: 'Find us on social media',
    click_map: 'Click on the map',
    about_store: 'About our store',
    established_info:
        "Established in 2013, we are Ireland's fastest growing retailer of Sports Nutrition, Weight Loss and Fitness Supplements",
    we_offer:
        'We offer a wide range of Bodybuilding Supplements including: Protein Shakes, Weight Gainers, Amino Acids, Performance and Recovery Supplements at competitive and affordable prices.',
    located_extended:
        'We are located in the heart of Dublin, at 20 Abbey Street Upper, opposite the main entrance to Jervis Shopping Centre, FLYEfit Gym and Jervis Luas Stop.',
    we_stock:
        "We stock world's leading brands including: BSN, Biotech USA, Olimp Nutrition, Optimum Nutrition, Mutant, Scitec Nutrition and Universal Nutrition.",
    meet_team: 'Meet the team',
    store_manager: 'Store Manager',
    personal_trainer: 'Personal Trainer',
    qualified_dietician: 'Qualified Dietician',
    bodybuilder: 'Bodybuilder',
    nabba_winner: 'NABBA Winner',
    answer_questions:
        "I'm here to give you tips and answer any questions about supplements, training or diet.",
    drop_in: 'Feel free to drop by for a chat.',
    partners: 'partnership',
    megapump_offer: 'Online shoppers can use promo code MEGA5 on our partner store—megapump.ie—to receive an exclusive 5% discount.',
}

export default homepage
