const homepage = {
    title: 'Bem-vindo',
    welcome: 'Bem-vindo ao Protein Zone',
    opening_times: 'Horário de funcionamento',
    mon: 'Segunda-feira',
    tue: 'Terça-feira',
    wed: 'Quarta-feira',
    thu: 'Quinta-feira',
    fri: 'Sexta-feira',
    sat: 'Sábado',
    sun: 'Domingo',
    visit_store: 'Visite nossa loja',
    in_centre: 'Estamos bem no coração de Dublin',
    contact_us: 'Contato',
    call_us: 'Entre em contato',
    find_us_on_social: 'Siga-nos nas redes sociais',
    click_map: 'Clique no mapa',
    about_store: 'Sobre nossa loja',
    established_info:
        'Estabelecido em 2013, somos varejista de suplementos de nutrição esportiva, perda de peso e condicionamento físico que mais cresce na Irlanda.',
    we_offer:
        'Oferecemos uma ampla variedade de suplementos de musculação, incluindo: vitaminas de proteína, ganhadores de massa, aminoácidos, suplementos de desempenho e recuperação a preços competitivos e acessíveis.',
    located_extended:
        'Estamos localizados no coração de Dublin, na 20 Abbey Street Upper, em frente a entrada principal do Jervis Shopping Centre, FLYEfit Gym e Jervis Luas Stop.',
    we_stock:
        'Temos em estoque as marcas líderes mundiais, incluindo: BSN, Biotech USA, Olimp Nutrition, Optimum Nutrition, Mutant, Scitec Nutrition e Universal Nutrition.',
    meet_team: 'Conheça a equipe',
    store_manager: 'Gerenta da Loja',
    personal_trainer: 'Personal Trainer',
    qualified_dietician: 'Nutricionista',
    bodybuilder: 'Fisiculturista',
    nabba_winner: 'Vencedor do NABBA',
    answer_questions:
        'Estou aqui para dar a você dicas e responder qualquer dúvida sobre suplementos, treino ou dieta.',
    drop_in: 'Fique à vontade para vir e bater um papo.',
    partners: 'Parceria',
    megapump_offer: 'Os compradores online podem usar o código promocional MEGA5 em nossa loja parceira—megapump.ie—para receber um desconto exclusivo de 5%.',

}

export default homepage
